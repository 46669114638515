<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r" slot-scope="{ data }">
        <el-button
          v-if="hasPerms('M12SM06_1')"
          @click="addOrEditItem"
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
        <el-button
          icon="el-icon-document"
          class="export-btn"
          @click="downloadTemplate"
          >下载导入模板</el-button
        >
        <el-button
          v-if="hasPerms('M12SM06_4')"
          icon="el-icon-download"
          class="export-btn"
          @click="importData"
          >导入</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          v-if="hasPerms('M12SM06_5')"
          @click="exportData(data.params)"
          icon="el-icon-upload2"
          class="export-btn"
          >导出</el-button
        ></template
      >
      <template slot="deviceType" slot-scope="{ data }">
        {{ getDictLabel(data.row.deviceType, dictList.ProductTerminal) }}
      </template>
      <template slot="cameraLine" slot-scope="{ data }">
        {{ JSON.parse(data.row.cameraLine || "[]").join(",") }}
      </template>
      <template slot="action" slot-scope="{ data }">
        <el-button
          v-if="hasPerms('M12SM06_2')"
          type="text"
          @click="addOrEditItem(data.row, true)"
          >修改</el-button
        >
        <el-button
          v-if="hasPerms('M12SM06_3')"
          @click="deleteItem(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </template>
    </SimpleTable>
    <TerminalAddOrEditDialog
      :show.sync="show"
      :dictList="dictList"
      :detailData="detailData"
      @submit="
        () => {
          $refs.table.getData(true);
        }
      "
    ></TerminalAddOrEditDialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import TerminalAddOrEditDialog from "./components/TerminalAddOrEditDialog";
import { ProductTerminal } from "@/js/const/vehicle.js";
import { dictionaryBatch } from "@/api/policy";
import {
  getTerminalList,
  exportTerminal,
  deleteTerminal,
  downloadTerminalTemplate,
  importTerminal,
} from "@/api/vehicleEquipment";
import { uploadFile } from "@/utils/index";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { SimpleTable, TerminalAddOrEditDialog },
  data() {
    return {
      hasPerms: hasPerms,
      show: false,
      detailData: {},
      dictList: {
        ProductTerminal,
        simCardType: [],
        protocolType: [],
        terminalVendors: [],
      },
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        isFuzzyQuery: false,
        height: 500,
        defaultColumnWidth: 120,
        searchConfigs: [],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "terminalNo",
            label: "终端编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "terminalSerialNumber",
            label: "终端序列号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "terminalType",
            label: "协议类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "deviceType",
            label: "终端类型",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "cameraLine",
            label: "视频通道",
            width: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "manufactorType",
            label: "终端供应商",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "plate",
            label: "绑定设备",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "frameNo",
            label: "车架号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "sim",
            label: "SIM卡ICCID",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "simType",
            label: "SIM卡供应商",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "activationTime",
            label: "激活日期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "expireTime",
            label: "费用到期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "stateRemark",
            label: "状态",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createUserName",
            label: "创建人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "创建时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            slot: true,
            isShow: true,
            fixed: "right",
          },
        ],
      },
    };
  },
  created() {
    this.setSearchConfigs();
    this.getDict();
  },
  methods: {
    getDict() {
      let dictKeys = ["simCardType", "protocolType", "terminalVendors"];
      dictionaryBatch({ codes: dictKeys.join(",") }).then((res) => {
        dictKeys.forEach((key) => {
          this.dictList[key] = res?.data[key] || [];
        });
        this.setSearchConfigs();
      });
    },
    setSearchConfigs() {
      this.tableProps.searchConfigs = [
        {
          label: "终端编号",
          type: "input",
          modelKey: "terminalNo",
          width: "240px",
        },
        {
          label: "终端序列号",
          type: "input",
          modelKey: "terminalSerialNumber",
          width: "240px",
        },
        {
          label: "终端类型",
          type: "select",
          modelKey: "deviceType",
          width: "240px",
          option: ProductTerminal.map((item) => {
            return {
              dictKey: item.value,
              dictValue: item.label,
            };
          }),
        },
        {
          label: "协议类型",
          type: "select",
          modelKey: "terminalTypeValue",
          width: "240px",
          option: this.dictList.protocolType,
        },
        {
          label: "终端供应商",
          type: "select",
          modelKey: "manufactorTypeValue",
          width: "240px",
          option: this.dictList.terminalVendors,
        },
        {
          label: "SIM卡ICCID",
          type: "input",
          modelKey: "serialNo34",
          width: "240px",
        },
        {
          label: "SIM卡供应商",
          type: "select",
          modelKey: "simTypeValue",
          width: "240px",
          option: this.dictList.simCardType,
        },
        {
          label: "状态",
          type: "select",
          modelKey: "state",
          width: "240px",
          option: [
            { dictKey: "", dictValue: "全部" },
            { dictKey: true, dictValue: "正常" },
            { dictKey: false, dictValue: "过期" },
          ],
        },
        {
          label: "创建人",
          type: "input",
          modelKey: "createUserName",
          width: "240px",
        },
        {
          label: "创建时间",
          type: "daterange",
          width: "240px",
          daterangeKeys: ["createDateTimeStart", "createDateTimeEnd"],
          // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
          modelKey: "createT",
        },
      ];
    },
    exportData(queryParams) {
      exportTerminal(queryParams);
    },
    deleteItem(data) {
      this.$confirm("此操作将永久删除该终端, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTerminal({ id: data.id }).then((res) => {
          if (res.flag) {
            this.$refs.table.getData(true);
            this.$message({
              message: "删除成功!",
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      });
    },
    addOrEditItem(row, type = false) {
      if (type) {
        this.detailData = row;
      } else {
        this.detailData = {};
      }
      this.show = true;
    },
    getDictLabel(value, dict) {
      return dict.find((item) => item.value == value)?.label || "";
    },
    // 下载导入模板
    downloadTemplate() {
      downloadTerminalTemplate();
    },
    // 导入数据
    importData() {
      uploadFile({ accept: "*", fileSize: 10 }).then(async (res) => {
        let formData = new FormData();
        formData.append("file", res);
        importTerminal(formData).then((res) => {
          if (res.flag == 1) {
            this.$message({
              message: "导入成功!",
              type: "success",
            });
            this.$refs.table.getData(true);
          } else {
            this.$message({
              message: "导入失败!",
              type: "error",
            });
          }
        });
      });
    },
    async queryFun(queryParams) {
      let res = await getTerminalList(queryParams);
      return {
        localData: res?.obj?.list || [],
        total: res?.obj?.total || 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
