//终端类型 (根据majorGrade判定谁是主终端，-1不可以是主终端)
export const TerminalTypeDict = [
  {
    value: 1,
    label: "视频终端(OVP)",
    isVideo: true,
    majorGrade: 2,
  },
  {
    value: 2,
    label: "OBD终端",
    majorGrade: 1,
  },
  {
    value: 3,
    label: "有线GPS",
    majorGrade: -1,
  },
  {
    value: 4,
    label: "无线GPS",
    majorGrade: -1,
  },
  {
    value: 5,
    label: "PFT",
    majorGrade: -1,
  },
  {
    value: 6,
    label: "BSD",
    majorGrade: 3,
  },
  {
    value: 7,
    label: "AEB",
    majorGrade: -1,
  },
];

//设备性质
export const DeviceNatureDict = [
  {
    value: false,
    label: "工机",
  },
  {
    value: true,
    label: "商车",
  },
];

// 产品终端(标准版)
export const ProductTerminal = [
  {
    value: 1,
    label: "视频终端",
    isVideo: true,
    majorGrade: 2,
  },
  {
    value: 2,
    label: "OBD",
    isVideo: false,
    majorGrade: 1,
  },
  {
    value: 3,
    label: "定位器",
    isVideo: false,
    majorGrade: 4,
  },
  {
    value: 7,
    label: "AEB",
    isVideo: false,
    majorGrade: 3,
  },
];

// 产品终端(商车版)
export const ProductTerminalCommerce = [
  {
    value: 1,
    label: "视频终端",
    isVideo: true,
    majorGrade: 3,
  },
  {
    value: 2,
    label: "OBD",
    isVideo: false,
    majorGrade: 2,
  },
  {
    value: 3,
    label: "定位器",
    isVideo: false,
    majorGrade: 4,
  },
  {
    value: 7,
    label: "AEB",
    isVideo: false,
    majorGrade: 1,
  },
];
