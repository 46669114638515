import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";

const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);

//添加终端设备
export const addOrUpdateTerminal = (data) => {
  return axios.axios({
    url: "/date/terminal/addOrUpdate",
    method: "post",
    data: data,
  });
};
// 根据设备性质查终端版本信息列表
export const getVersionListByEquipmentNature = (params) => {
  return axios.axios({
    url: "/date/version/listByEN",
    method: "get",
    params: params,
  });
};

// 根据终端类型查终端列表
export const getTerminalListByEquipmentType = (params) => {
  return axios.axios({
    url: "/date/terminal/list",
    method: "get",
    params: params,
  });
};

// 终端列表
export const getTerminalList = (data) => {
  return axios.axios({
    url: "/date/terminal/page",
    method: "POST",
    data: data,
  });
};

// 终端列表导出
export const exportTerminal = (data) => {
  return axios.axios({
    url: "/date/terminal/exportExcel",
    method: "POST",
    data: data,
    responseType: "blob",
  });
};

// 删除终端
export const deleteTerminal = (params) => {
  return axios.axios({
    url: "/date/terminal/delete",
    method: "GET",
    params: params,
  });
};

//添加产品版本
export const addOrUpdateProductVersion = (data) => {
  return axios.axios({
    url: "/date/version/addOrUpdate",
    method: "post",
    data: data,
  });
};

//产品版本列表
export const getProductVersionList = (data) => {
  return axios.axios({
    url: "/date/version/page",
    method: "POST",
    data: data,
  });
};

//删除产品版本
export const deleteProductVersion = (params) => {
  return axios.axios({
    url: "/date/version/delete",
    method: "GET",
    params: params,
  });
};

// 设备列表
export const getEquipmentList = (data) => {
  return axios.axios({
    url: "/vehicle/v2/page",
    method: "POST",
    data: data,
  });
};

//设备导出
export const exportEquipment = (data) => {
  return axios.axios({
    url: "/vehicle/v2/exportExcel",
    method: "POST",
    data: data,
    responseType: "blob",
  });
};

//删除设备
export const deleteEquipment = (params) => {
  return axios.axios({
    url: "/vehicle/delteVehicle.do",
    method: "GET",
    params: params,
  });
};

// 设备详情
export const getEquipmentDetail = (params) => {
  return axios.axios({
    url: "/vehicle/v2/details",
    method: "GET",
    params: params,
  });
};

//模糊查询车辆客户
export const getEquipmentCustomerList = (params) => {
  return axios.axios({
    url: "/date/customer/list",
    method: "GET",
    params: params,
  });
};

// 添加或编辑设备
export const addOrEditEquipment = (data) => {
  return axios.axios({
    url: "/vehicle/v2/addOrUpdate",
    method: "POST",
    data: data,
  });
};

//下载终端导入模板
export const downloadTerminalTemplate = () => {
  return axios.axios({
    url: "/date/terminal/downloadTemplate",
    method: "GET",
    responseType: "blob",
  });
};

// 导入终端数据
export const importTerminal = (data) => {
  return axios.axios({
    url: "/date/terminal/import",
    method: "POST",
    data: data,
  });
};
