var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"添加","visible":_vm.dialogVisible,"close-on-click-modal":false,"destroy-on-close":"","width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"terminalNo"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'input',
          required: true,
          label: '终端编号：',
          isNum: true,
          width: '100%',
        }},model:{value:(_vm.dataForm.terminalNo),callback:function ($$v) {_vm.$set(_vm.dataForm, "terminalNo", $$v)},expression:"dataForm.terminalNo"}})],1),_c('el-form-item',{attrs:{"prop":"terminalSerialNumber"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'input',
          label: '终端序列号：',
          width: '100%',
        }},model:{value:(_vm.dataForm.terminalSerialNumber),callback:function ($$v) {_vm.$set(_vm.dataForm, "terminalSerialNumber", $$v)},expression:"dataForm.terminalSerialNumber"}})],1),_c('el-form-item',{attrs:{"prop":"terminalTypeValue"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'select',
          label: '协议类型：',
          required: true,
          width: '100%',
          option: _vm.dictList.protocolType,
          modelTextKey: 'terminalTypeKey',
        },"optionConfig":{
          value: 'dictKey',
          label: 'dictValue',
        }},on:{"returnVal":_vm.returnVal},model:{value:(_vm.dataForm.terminalTypeValue),callback:function ($$v) {_vm.$set(_vm.dataForm, "terminalTypeValue", $$v)},expression:"dataForm.terminalTypeValue"}})],1),_c('el-form-item',{attrs:{"prop":"deviceType"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'select',
          label: '终端类型：',
          required: true,
          width: '100%',
          option: _vm.dictList.ProductTerminal,
        }},model:{value:(_vm.dataForm.deviceType),callback:function ($$v) {_vm.$set(_vm.dataForm, "deviceType", $$v)},expression:"dataForm.deviceType"}})],1),_c('el-form-item',{attrs:{"prop":"manufactorTypeValue"}},[_c('TextInput',{attrs:{"labelPosition":"left","config":{
          type: 'select',
          label: '终端供应商：',
          required: true,
          width: '100%',
          modelTextKey: 'manufactorTypeKey',
          option: _vm.dictList.terminalVendors,
        },"optionConfig":{
          value: 'dictKey',
          label: 'dictValue',
        }},on:{"returnVal":_vm.returnVal},model:{value:(_vm.dataForm.manufactorTypeValue),callback:function ($$v) {_vm.$set(_vm.dataForm, "manufactorTypeValue", $$v)},expression:"dataForm.manufactorTypeValue"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }